import React, { Component } from "react";
import store from "../../../../store";
import { Column, Editing, Paging, Texts, SearchPanel, Lookup, RequiredRule, Pager } from "devextreme-react/data-grid";
import { Button, DataGrid, TreeList, SelectBox } from "devextreme-react";
import "devextreme-react/text-area";
import { Link } from "react-router-dom";
import "./WebShops.scss";
import {
    getWebShopsByLicenseId,
    updateWebShop,
    insertWebShop,
    getTakeAwaysByLicenseId,
    updateTakeAway,
    insertTakeAway,
    deleteTakeAway,
    getWebShopTypes,
} from "../../../../actions/webShopActions";
import { getServerUrls } from "../../../../actions/serverUrlActions";
import { getAvailableVersions, getLicenseById, startInstance, stopInstance } from "../../../../actions/licensesActions";
import { createShop, deployShop, getAvailableShopVersions, updateShop } from "../../../../actions/shopActions";

class Webshops extends Component {
    constructor(props) {
        super(props);

        this.state = {
            webShops: [],
            takeAwayShops: [],
            webShopTypes: [],
            serverUrls: [],
            licenseId: 0,
            license: {},
            availableVersions: {},
        };

        this.handleWebShopsInsert = this.handleWebShopsInsert.bind(this);
        this.handleWebShopsUpdate = this.handleWebShopsUpdate.bind(this);
        this.handleWebShopsInit = this.handleWebShopsInit.bind(this);

        this.handleTakeAwayRemove = this.handleTakeAwayRemove.bind(this);
        this.handleTakeAwayInsert = this.handleTakeAwayInsert.bind(this);
        this.handleTakeAwayUpdate = this.handleTakeAwayUpdate.bind(this);
        this.handleTakeAwayInit = this.handleTakeAwayInit.bind(this);
        this.editShopButton = this.editShopButton.bind(this);
        this.handleEditShop = this.handleEditShop.bind(this);
        this.updateShop = this.updateShop.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleDeploy = this.handleDeploy.bind(this);
    }

    componentDidMount() {
        const { licenseId } = this.props;
        this.setState({
            licenseId: licenseId,
        });
        store.dispatch(getWebShopTypes()).then((json) => {
            this.setState({ webShopTypes: json });
        });
        store.dispatch(getServerUrls()).then((json) => {
            this.setState({ serverUrls: json });
        });
        store.dispatch(getLicenseById(licenseId)).then((result) => {
            console.log("result", result);
            this.setState({
                license: result,
            });
        });
        store.dispatch(getAvailableShopVersions("mensashop")).then((json) => {
            this.setState({ availableVersions: { ...this.state.availableVersions, mensashop: json } });
        });
        store.dispatch(getAvailableShopVersions("ggbiosshop")).then((json) => {
            this.setState({ availableVersions: { ...this.state.availableVersions, ggbiosshop: json } });
        });
    }

    editShopButton(e) {
        console.log(e);
        return (
            <div style={{ display: "flex", justifyContent: "spaceBetween" }}>
                <Link to={"/webshop/" + e.value}>
                    <span>Bearbeiten</span>
                </Link>
            </div>
        );
    }

    handleEditShop(e) {
        console.log(e);
    }

    render() {
        return (
            <div className="webshops">
                {this.state.license && this.state.license.Shops && (
                    <div className="licenses">
                        <h1>Webshops</h1>
                        <TreeList
                            dataSource={this.state.license.Shops}
                            showBorders={true}
                            showRowLines={true}
                            columnAutoWidth={true}
                            rowAlternationEnabled={true}
                            onInitialized={this.handleInit}
                            onRowUpdated={this.handleUpdate}
                            onRowInserting={this.handleCreate}
                            keyExpr="shopId"
                        >
                            <Paging defaultPageSize={20} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} showInfo={true} />
                            <Editing allowUpdating={true} allowAdding={true} allowDeleting={false}>
                                <Texts
                                    addRow="Shop hinzufügen"
                                    editRow="Bearbeiten"
                                    cancelRowChanges="Abbrechen"
                                    saveRowChanges="Speichern"
                                />
                            </Editing>
                            <Column dataField="shopName" caption={"Name"}>
                                <RequiredRule />
                            </Column>
                            <Column
                                dataField="shopKind"
                                caption={"Art des Shops"}
                                cellRender={({ data }) => (
                                    <SelectBox items={["mensashop", "ggbiosshop"]} value={data.shopKind} />
                                )}
                            >
                                <RequiredRule />
                            </Column>
                            <Column dataField="url" caption={"ShopUrl"}>
                                <RequiredRule />
                            </Column>
                            <Column
                                dataField="image"
                                caption={"Image"}
                                cellRender={({ data }) => (
                                    <SelectBox
                                        items={this.state.availableVersions[data.shopKind]}
                                        value={
                                            data.image
                                                ? data.image.replace(
                                                      "foodgenius.azurecr.io/bios/" + data.shopKind + ":",
                                                      ""
                                                  )
                                                : "release"
                                        }
                                        onValueChanged={(e) => {
                                            if (
                                                e.value !==
                                                data.image.replace(
                                                    "foodgenius.azurecr.io/bios/" + data.shopKind + ":",
                                                    ""
                                                )
                                            ) {
                                                this.updateShop({
                                                    ...data,
                                                    image:
                                                        "foodgenius.azurecr.io/bios/" + data.shopKind + ":" + e.value,
                                                });
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Column dataField="deployStatus" caption={"Deploy Status"} />
                            <Column dataField="keycloakRealmName" caption={"Keycloak Realm"} />
                            <Column
                                caption="Deploy"
                                cellRender={({ data }) => (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            onClick={() => this.handleDeploy(data)}
                                            type="default"
                                            style={{ height: 30, marginRight: 5 }}
                                        >
                                            Deploy
                                        </Button>
                                        <Button
                                            onClick={() => this.startShop(data)}
                                            type="default"
                                            style={{ height: 30, marginRight: 5 }}
                                        >
                                            Starten
                                        </Button>
                                        <Button
                                            onClick={() => this.stopShop(data)}
                                            type="danger"
                                            style={{ height: 30 }}
                                        >
                                            Stoppen
                                        </Button>
                                    </div>
                                )}
                            ></Column>
                        </TreeList>
                    </div>
                )}

                {/*     <div>
                    <h1>Lieferando Onlineshops</h1>
                    <DataGrid
                        dataSource={this.state.takeAwayShops}
                        keyExpr="TakeAwayId"
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        onInitialized={this.handleTakeAwayInit}
                        onRowRemoved={this.handleTakeAwayRemove}
                        onRowUpdated={this.handleTakeAwayUpdate}
                        onRowInserting={this.handleTakeAwayInsert}
                    >
                        <SearchPanel visible={true} width={240} placeholder="Suche..." />
                        <Paging enabled={false} />
                        <Editing mode={"cell"} allowUpdating={true} allowAdding={true} allowDeleting={true}>
                            <Texts
                                deleteRow="Löschen"
                                confirmDeleteMessage="Soll dieser Eintrag wirklich gelöscht werden?"
                            />
                        </Editing>
                        <Column dataField="TakeAwayId" caption={"ID"} allowEditing={false} width="60px" />
                        <Column dataField="TakeAwayRestaurantId" caption={"Lieferando Restaurant ID"} />
                        <Column dataField="serverName" caption={"Servername"} />
                    </DataGrid>
                </div> */}
            </div>
        );
    }

    handleCreate(e) {
        console.log(e);
        e.data.licenseId = this.state.licenseId;
        store.dispatch(createShop(e.data)).then((response) => {
            store.dispatch(getLicenseById(this.props.licenseId)).then((result) => {
                console.log("result", result);
                this.setState({
                    license: result,
                });
            });
        });
    }

    handleDeploy(data) {
        store.dispatch(deployShop(this.state.licenseId, data.shopId)).then((response) => {
            store.dispatch(getLicenseById(this.props.licenseId)).then((result) => {
                console.log("result", result);
                this.setState({
                    license: result,
                });
            });
        });
    }

    updateShop(shop) {
        store.dispatch(updateShop(shop)).then((response) => {
            store.dispatch(getLicenseById(this.props.licenseId)).then((result) => {
                console.log("result", result);
                this.setState({
                    license: result,
                });
            });
        });
    }

    handleWebShopsInit(e) {
        const { licenseId } = this.props;
        e.component.beginCustomLoading();
        store.dispatch(getWebShopsByLicenseId(licenseId)).then((json) => {
            this.setState({ webShops: json });
            e.component.endCustomLoading();
        });
    }

    handleWebShopsUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateWebShop(e.data)).then((response) => {
            this.handleWebShopsInit(e);
            e.component.endCustomLoading();
        });
    }

    handleWebShopsInsert(e) {
        e.component.beginCustomLoading();
        let webShop = e.data;
        webShop.LicenseId = this.props.licenseId;
        store.dispatch(insertWebShop(webShop)).then((response) => {
            this.handleWebShopsInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayInit(e) {
        const { licenseId } = this.props;
        e.component.beginCustomLoading();
        store.dispatch(getTakeAwaysByLicenseId(licenseId)).then((json) => {
            this.setState({ takeAwayShops: json });
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayUpdate(e) {
        e.component.beginCustomLoading();
        store.dispatch(updateTakeAway(e.data)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }

    handleTakeAwayRemove(e) {
        e.component.beginCustomLoading();
        store.dispatch(deleteTakeAway(e.data)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }

    startShop(e) {
        console.log(e);
        store.dispatch(startInstance(e.shopKind, e.url.replaceAll(".", "-")));
    }

    stopShop(e) {
        console.log(e);
        store.dispatch(stopInstance(e.shopKind, e.url.replaceAll(".", "-")));
    }

    handleTakeAwayInsert(e) {
        e.component.beginCustomLoading();
        let webShop = e.data;
        webShop.licenseId = this.state.licenseId;
        store.dispatch(insertTakeAway(webShop)).then((response) => {
            this.handleTakeAwayInit(e);
            e.component.endCustomLoading();
        });
    }
}

export default Webshops;

import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Container extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ maxWidth: "1000px", display: "flex", height: 40 }}>
                <div
                    style={{
                        width: 500,
                        borderStyle: "solid",
                        borderWidth: 1,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingLeft: 10,
                    }}
                >
                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href={
                            "https://" +
                            (this.props.customDomain != null && this.props.customDomain !== ""
                                ? this.props.customDomain
                                : this.props.frontendUrl)
                        }
                    >
                        https://
                        {this.props.customDomain != null && this.props.customDomain !== ""
                            ? this.props.customDomain
                            : this.props.frontendUrl}
                    </a>
                </div>
                <div
                    style={{
                        width: 300,
                        borderStyle: "solid",
                        borderWidth: 1,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingLeft: 10,
                    }}
                >
                    {this.props.License ? (
                        <Link to={"/lizenzen/" + this.props.License.LicenseId}>{this.props.License.StoreName}</Link>
                    ) : (
                        "frei"
                    )}
                </div>
                <div
                    style={{
                        width: 200,
                        borderStyle: "solid",
                        borderWidth: 1,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        paddingLeft: 10,
                    }}
                >
                    {this.props.deployStatusString}
                </div>
            </div>
        );
    }
}
